/* eslint no-unused-vars: "off"*/
(function($, _, Bloodhound) {
  var pluginName = 'QuickOrder';
  var defaults = {
    bloodhound: {
      remote: {
        url: '../suggest?OpenAgent&q=%QUERY',
        wildcard: '%QUERY',
      },
    },
    typeahead: {
      enabled: true,
      highlight: true,
      minLength: 2,
      limit: 6,
      template: _.template(
          '<div class="<%= section %>">' +
          '<strong><%- sku %></strong><br>' +
          '<%- description %><br>' +
          '<% if (mpn && manufacturer) { %>' +
          '<%- mpn %> : <%- manufacturer %><div>' +
          '<% } else { %>' +
          '&nbsp;' +
          '<% } %>' +
          '</div>'),
    },
  };

  function QuickOrder(element, options) {
    this.$element = $(element);
    this.options = _.defaultsDeep({}, options, defaults);

    this._defaults = defaults;
    this._name = pluginName;

    this.init();
  }

  QuickOrder.prototype = {
    init: function() {
      if (this.options.typeahead.enabled) {
        var articles = new Bloodhound({
          datumTokenizer: Bloodhound.tokenizers.obj.whitespace('sku'),
          queryTokenizer: Bloodhound.tokenizers.whitespace,
          remote: {
            url: this.options.bloodhound.remote.url,
            wildcard: this.options.bloodhound.remote.wildcard,
          },
        });
        $('input.qo-article').typeahead(null, {
          name: 'articles',
          display: 'sku',
          highlight: this.options.typeahead.highlight,
          minLength: this.options.typeahead.minLength,
          limit: this.options.typeahead.limit,
          source: articles,
          templates: {
            suggestion: this.options.typeahead.template,
          },
        });
      }

      this.$element.find('.qo-delete').on('click', function(e) {
        this.resetInput($(e.target).closest('tr').find('input'));
        this.updateGlobalErrorMessage(this.$element);
      }.bind(this));
    },

    resetInput: function($input) {
      if ($input.typeahead) {
        $input.removeClass('error').css({background: '#fff'}).typeahead('val', '');
      } else {
        $input.removeClass('error').val('');
      }
    },

    updateGlobalErrorMessage: function($element) {
      if ($element.find('input.error').length === 0) {
        $element.find('div.qo-error').remove();
      }
    },
  };

  $.fn[pluginName] = function(options) {
    return this.each(function() {
      if (!$.data(this, 'plugin_' + pluginName)) {
        $.data(this, 'plugin_' + pluginName,
            new QuickOrder(this, options));
      }
    });
  };
})(jQuery, _, Bloodhound);
