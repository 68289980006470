/**
 * jQuery-Dropdown-Plugin
 * @author: stefan@fam-klinke.de
 * @version: 1.0
 */

var pluginName = 'Dropdown';
var defaults = {};

function Dropdown( element, options ) {
  this.element = element;
  this.$element = $(this.element);
  this.dropdown = element.nextElementSibling;
  this.$dropdown = $(this.dropdown);
  this.options = _.defaultsDeep( {}, options, defaults);

  this._defaults = defaults;
  this._name = pluginName;

  this.init();
}

Dropdown.prototype = {
  init: function() {
    $(document.body).on('click.' + pluginName, function(evt) {
      if (evt.target !== this.element && evt.target !== this.dropdown && !$.contains(this.dropdown, evt.target)) {
        this.$dropdown.removeClass('show');
      }
    }.bind(this));
    this.$element.on('click.' + pluginName, function() {
      // console.debug('click.' + pluginName, this);
      this.$dropdown.toggleClass('show');
    }.bind(this));
  },
};

$.fn[pluginName] = function( options ) {
  return this.each(function() {
    if (!$.data(this, 'plugin_' + pluginName)) {
      $.data(this, 'plugin_' + pluginName,
          new Dropdown( this, options ));
    }
  });
};
