interface Window {
  moment: any;
  Piwik: any;
}
declare let window: Window;

import 'jquery';
// import './polyfill/CustomEvent'
import './standard/no-js';

import 'native-promise-only';
import 'lodash';
import 'validate.js';
import {default as moment} from '../../node_modules/moment/src/moment';
window.moment = moment;
import './pikaday';
// import '../../node_modules/pikaday/plugins/pikaday.jquery';

import './dropdown';
import './cookies';
import './jquery.socialshareprivacy';
import './trackingHelper';
import './accordion';
import './slick';
import '../../node_modules/corejs-typeahead/dist/typeahead.bundle.js';
import './spinner';
import './jquery.colorbox';
import './modal';
import '../plugins/QuickOrder/quickorder'

// requires rollup option output.strict: false
import './piwik';
declare let Piwik: any;
window.Piwik = Piwik;
