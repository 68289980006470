/**
 * Parses the cookie string provided by document.cookie into an object.
 * @returns {object} Cookies and their values
 */
function getCookies(): object {
	const cookies: object = {};
	if (document.cookie.length > 2) {
		const strings: Array<string> = document.cookie.split(';');
		for (let i: number = 0; i < strings.length; i++) {
			const kv: Array<string> = strings[i].split('=');
			cookies[kv[0].trim()] = kv[1].trim();
		}
	}
	return cookies;
}

window['getCookies'] = getCookies;
