// Uses CommonJS, AMD or browser globals to create a jQuery plugin.

(function(factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    module.exports = function(root, jQuery) {
      if (jQuery === undefined) {
        // require('jQuery') returns a factory that requires window to
        // build a jQuery instance, we normalize how we use modules
        // that require this pattern but the window provided is a noop
        // if it's defined (how jquery works)
        if (typeof window !== 'undefined') {
          jQuery = require('jquery');
        } else {
          jQuery = require('jquery')(root);
        }
      }
      factory(jQuery);
      return jQuery;
    };
  } else {
    // Browser globals
    factory(jQuery);
  }
}(function($) {
  var pluginName = 'accordion';

  function Accordion(element) {
    this.$element = $(element);
    this._name = pluginName;

    this.init();
  }
  Accordion.prototype = {
    init: function() {
      var _this = this;
      this.$element.find('.accordion-header').each(function(_, element) {
        _this.setMaxHeight($(element));
      });

      this.$element.find('.accordion-header').on('click', function() {
        var $this = $(this);
        $this.toggleClass('active');
        _this.setMaxHeight($this);
      });
    },
    /* Toggle between hiding and showing the active content */
    setMaxHeight: function($e) {
      if ($e.hasClass('active')) {
        $e.next('.accordion-content').css('max-height', function() {
          return this.scrollHeight;
        });
      } else {
        $e.next('.accordion-content').css('max-height', '0');
      }
    },
  };

  $.fn[pluginName] = function( options ) {
    return this.each(function() {
      if (!$.data(this, 'plugin_' + pluginName)) {
        $.data(this, 'plugin_' + pluginName,
            new Accordion( this, options ));
      }
    });
  };
}));
