interface Window {
  tracker: any;
}

(function ($, window) {
  const _seen = {};
  (<any>$.fn).trackImpressions = function () {
    this.find('[data-track-content]').each(function () {
      const $c = $(this);
      const name: string = $c.attr('data-content-name') || $c.find('[data-content-name]').attr('data-content-name');
      $c.add('[data-content-piece]', this).each(function () {
        const $p = $(this);
        const piece = getPiece($p);
        if (piece) {
          const k = name + '.' + piece;
          if (!_seen[k]) {
            const target = getTarget($p);
            const touchObject: any = {
              dragging: false,
              distance: 0,
            };
            $p.on('touchstart.track mousedown.track', function (e) {
              touchObject.dragging = true;
              touchObject.start = getCoordinates(e);
              touchObject.distance = 0;
            });
            $p.on('touchmove.track mousemove.track', function (e) {
              if (touchObject.dragging) {
                touchObject.cur = getCoordinates(e);
                touchObject.distance = Math.round(Math.abs(touchObject.start.x - touchObject.cur.x));
              }
            });
            $p.on('touchend.track mouseup.track', function (e) {
              // console.log('distance: ', touchObject.distance);
              touchObject.dragging = false;
              if (touchObject.distance < 15) {
                window.tracker.trackContentInteraction('click', name, piece, target);
              }
            });
            $p.on('touchcancel.track mouseleave.track', function (e) {
              touchObject.dragging = false;
            });
            window.tracker.trackContentImpression(name, piece, target);
            _seen[k] = true;
          }
        }
      });
    });
  };

  function getPiece($piece) {
    if ($piece.attr('data-content-piece')) {
      return $piece.attr('data-content-piece');
    } else if ($piece.attr('src')) {
      return canonicalizeHost($piece[0].src);
    } else {
      return $piece.attr('title');
    }
  }

  function getTarget($piece) {
    let target;
    if ($piece.attr('data-content-target')) {
      return $piece.attr('data-content-target');
    } else if ($piece.attr('href')) {
      target = $piece;
    } else if ($piece.find('a[href]').attr('href')) {
      target = $piece.find('a[href]');
    } else if ($piece.closest('a[href]').attr('href')) {
      target = $piece.closest('a[href]');
    }
    return canonicalizeHost(target[0].href);
  }

  function canonicalizeHost(str) {
    return str.replace(new RegExp('^https?:\/\/' + location.host), 'https://www.schukat.com');
  }

  function getCoordinates(event) {
    const touches = event.originalEvent !== undefined ? event.originalEvent.touches : null;
    return {
      x: touches ? touches[0].pageX : event.clientX,
      y: touches ? touches[0].pageY : event.clientY,
    };
  }
})(jQuery, window);

function trackSlickImpressions(slick, currentSlide: number) {
  for (let i = 0; i < slick.options.slidesToShow; i++) {
    let s = currentSlide + i;
    if (s > slick.slideCount) {
      s = s - slick.slideCount;
    }
    (<any>$(slick.$slides[s])).trackImpressions();
  }
}

window['trackSlickImpressions'] = trackSlickImpressions;
