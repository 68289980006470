import '../../node_modules/spin.js/spin';
import { Spinner } from '../../node_modules/spin.js/spin';

const opts = {
	lines: 8,
	length: 4,
	width: 5,
	radius: 12,
	scale: 1,
	corners: 0.75,
	speed: 1,
	rotate: 14,
	animation: 'spinner-line-fade-quick',
	direction: 1,
	color: '#a5b8c6',
	fadeColor: 'transparent',
	top: '49%',
	left: '50%',
	shadow: '0 0 1px transparent',
	// className: 'spinner', // The CSS class to assign to the spinner
	// position: 'absolute' // Element positioning
};
window['Spinner'] = Spinner;
const spin = new Spinner(opts);
window['spin'] = spin;
